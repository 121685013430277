<template>
  <div class="modal-body">
    <div class="flex justify-center p-4">
      <nav class="flex bg-side-color rounded-md" aria-label="Tabs">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          :href="tab.href"
          :class="[
            !tab.isVisible
              ? 'hidden'
              : tab.current
                ? 'border-slate-100 text-black bg-accent'
                : 'border-transparent text-slate-400  hover:text-gray-400 ',
            'rounded-md px-3 py-2 text-sm font-medium',
          ]"
          :aria-current="tab.current ? 'page' : undefined"
          @click="handleTabSelect(tab)"
          >{{ tab.name }}</a
        >
      </nav>
    </div>
    <div v-if="selectedTab === 'wager'" class="">
      <img class="w-full h-full" src="../../assets/vip.png" />
      <div class="p-4 pt-0">
        <div class="text-white">
          <div class="relative pt-1">
            <div class="flex items-center justify-between mb-2">
              <div class="text-xs">Your VIP Progress</div>
              <div class="text-right">
                <span class="inline-block text-xs font-semibold text-white">
                  {{ percentage }}
                </span>
              </div>
            </div>
            <div class="flex h-2 mb-2 overflow-hidden text-xs bg-gray-700 rounded-md">
              <div
                :style="{ width: percentage }"
                class="flex flex-col justify-center text-white bg-[#F96A40] rounded-md whitespace-nowrap"></div>
            </div>
            <div class="flex items-center justify-between mb-2">
              <div class="flex items-center">
                <img
                  v-if="lowerBound !== 'none' && !this.loading"
                  :src="getLowerBoundImage()"
                  class="w-8 h-8"
                  alt="" />

                <span class="pl-1 text-xs text-gray-400 capitalize">
                  {{ lowerBound === 'none' ? 'Unranked' : lowerBound }}
                </span>
              </div>
              <div class="flex items-center">
                <img
                  v-if="!this.loading"
                  :src="getUpperBoundImage()"
                  class="w-8 h-8"
                  alt="" />
                <span class="pl-1 text-xs text-gray-400 capitalize">
                  {{ upperBound }}
                </span>
              </div>
            </div>
            <div class="pt-4 text-xs leading-snug text-gray-400">
              Looking to achieve the next level? Simply play games for fun & free on our
              Social Casino
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 p-4">
      <div class="bg-night-turquoise p-4 rounded-lg text-center md:transition md:duration-150 md:ease-in-out md:hover:scale-105 hover:bg-[#012220]">
        <span class="text-lg bold font-bold text-white">Total Games</span>
      <div class="text-2xl font-bold mt-2 text-white">{{ gamesPlayed }}</div>
      </div>
      <div class="bg-night-turquoise p-4 rounded-lg text-center md:transition md:duration-150 md:ease-in-out md:hover:scale-105 hover:bg-[#012220]">
        <span class="text-lg bold font-bold text-white">Number of Wins</span>
        <div class="text-2xl font-bold mt-2 text-white">{{ wins }}</div>
      </div>
      <div class="bg-night-turquoise p-4 rounded-lg text-center md:transition md:duration-150 md:ease-in-out md:hover:scale-105 hover:bg-[#012220]">
        <span class="text-lg bold font-bold text-white">Number of Losses</span>
        <div class="text-2xl font-bold mt-2 text-white">{{ losses }}</div>
      </div>
      <div class="bg-night-turquoise p-4 rounded-lg text-center md:transition md:duration-150 md:ease-in-out md:hover:scale-105 hover:bg-[#012220]">
        <span class="text-lg bold font-bold text-white">Amount</span>
        <div class="text-2xl font-bold mt-2 relative text-white" :style="{ fontSize: dynamicFontSize }">
            {{ playAmount }} 
           
            <img
                  v-if="curGameMode  === 'SC'"
                  alt="icon"
                  src="../../assets/coins/cash.png"
                  class="ml-2 h-4 w-4 inline-block" />
            <img
                  v-if="curGameMode  === 'GC'"
                  alt="icon"
                  src="../../assets/coins/gold.png "
                  class="ml-2 h-4 w-4 inline-block" />
        </div>
      </div>
    </div>
      <div class="text-xs font-semibold text-center bg-onprimary text-black">
        <div class="py-2">Learn more about being a Punt VIP</div>
      </div>
    </div>

    <div v-else-if="selectedTab === 'rakeback'">
      <div class="p-4">
        <div class="text-white">
          <div class="relative pt-1">
            <div class="flex justify-center mb-2 text-xs">Available Rackback:</div>

            <div class="flex flex-col items-center justify-between py-4">
              <div class="flex items-center space-x-2">
                <span>
                  {{ rakeback }}
                </span>
                <span> <img class="w-5 h-5" :src="getIcon('SC')" /></span>
              </div>
            </div>
            <div class="flex justify-center">
              <button
                @click="handleClaim()"
                type="button"
                :disabled="isRakebackDisable || rakeback <= 0 ? true : false"
                :class="[
                  rakeback <= 0
                    ? 'bg-gray-400 text-gray-600 cursor-not-allowed'
                    : 'bg-green-500 hover:bg-green-500 text-gray-800',
                  'rounded-md  px-3.5 py-2.5 text-sm  shadow-sm',
                ]">
                Claim Rakeback
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-xs font-semibold text-center text-gray-400">
        <div class="py-2">Rakeback is accumulated each time you play a game!</div>
      </div>
    </div>
    <div v-else class="w-full">
      <div class="p-4 w-full"><benefits /></div>
    </div>
  </div>
</template>

<script>
import Benefits from '../Vip/Benefits.vue';
import axios from '../../lib/axiosConfig';
import { mapActions, mapGetters } from 'vuex';
import { getBalanceIcon } from '@/lib/icons';
import {formatRedemptionAmount} from '@/lib/utils';

export default {
  name: 'VIP',
  components: { Benefits },
  data() {
    return {
      lowerBound: '',
      upperBound: '',
      percentage: 0,
      rakeback: 0,
      isRakebackDisable: false,
      selectedTab: 'wager',
      basePath: '/images/ranks/',
      lowerBoundImageCode: '',
      upperBoundImageCode: '',
      loading: true,
      tabs: [
        {
          name: 'Progress',
          code: 'wager',
          href: '#',
          current: true,
          isVisible: true,
        },
        {
          name: 'Rakeback',
          code: 'rakeback',
          href: '#',
          current: false,
          isVisible: false,
        },
        {
          name: 'Benefits',
          code: 'benefits',
          href: '#',
          current: false,
          isVisible: true,
        },
      ],
      formatRedemptionAmount,
      wins: 0,
      losses: 0,
      gamesPlayed: 0,
      playAmount: 0,
      playAmountTmpValue: 0,
    };
  },
  mounted() {
    this.fetchUserRanks();
    this.fetchUserGamePlayStatistics();
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn', 'curGameMode']),
    dynamicFontSize() {
      const length = this.playAmount.toString().length;
      if (length <= 5) return '2rem'; 
      if (length <= 10) return '1.5rem'; 
      return '1rem'; 
    },
  },
  methods: {
    getLowerBoundImage() {
      return `${this.basePath}${this.lowerBoundImageCode}.png`;
    },
    getUpperBoundImage() {
      return `${this.basePath}${this.upperBoundImageCode}.png`;
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    handleTabSelect(tab) {
      this.selectedTab = tab.code;
      if (this.selectedTab === 'rakeback') {
        this.getUserRakeback(this.me.id).then((res) => {
          this.rakeback = res.data;
        });
      }
      this.tabs = this.tabs.map((i) => {
        if (i.code === tab.code) {
          i.current = true;
        } else {
          i.current = false;
        }
        return i;
      });
    },
    fetchUserRanks() {
      this.getUserRanks(this.me.id).then((res) => {
        this.lowerBoundImageCode = res.data.lower_bound_code;
        this.upperBoundImageCode = res.data.upper_bound_code;
        this.lowerBound = res.data.lower_bound;
        this.upperBound = res.data.upper_bound;
        this.percentage = res.data.percentage;
        this.loading = false;
        if (res.data.lower_bound !== 'none') {
          this.tabs = this.tabs.map((i) => {
            if (i.code === 'rakeback') {
              i.isVisible = true;
            }
            return i;
          });
        }
      });
    },
    handleClaim() {
      if (this.isRakebackDisable === false) {
        this.isRakebackDisable = true;
        this.claimRakeback(this.me.id).then((res) => {
          this.toggleProgressModal();
          this.$toast.success(`${this.rakeback} SC Rakeback successfully claimed!`);
        });
      }
    },

    toggleProgressModal() {
      this.isWagerProgressVisible = !this.isWagerProgressVisible;
      this.selectedTab = 'wager';
      this.isRakebackDisable = false;
      this.tabs = this.tabs.map((i) => {
        if (i.code === 'wager') {
          i.current = true;
        } else {
          i.current = false;
        }
        return i;
      });
      this.$emit('onClose');
    },
    fetchUserGamePlayStatistics() {
        return axios.get('/user-game-play-stats')
        .then(response => {
          this.wins = response.data?.[0]?.won || this.wins;
          this.losses = response.data?.[0]?.lost || this.losses;
          this.gamesPlayed = response.data?.[0]?.total_games || this.gamesPlayed;
          this.playAmountTmpValue = this.curGameMode==='SC'? response.data?.[0]?.total_scwager: response.data?.[0]?.total_gcwager;
          this.playAmount = formatRedemptionAmount(this.playAmountTmpValue) || this.playAmount;
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
    },
    ...mapActions(['getUserRanks', 'getUserRakeback', 'claimRakeback']),
  },
};
</script>
