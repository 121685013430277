<template>
  <div class="rounded-b-md md:flex">
    <div v-if="this.loading">
      <Loader class="fixed inset-0 z-[9999] flex justify-center items-center flex-col" />
    </div>

    <div class="hidden md:flex-auto md:w-2/4 md:block">
      <img src="../../assets/registration.jpg" class="login-r-banner" alt="login" />
    </div>
    <div class="flex-col md:w-2/4 md:flex md:relative md:flex-auto">
      <form
        class="registration-form"
        @submit.stop.prevent="() => radarTrackAndRegister()">
        <div
          class="md:px-16 md:pt-10 p-2 md:pb-7 pt-5 scrollbar-style overflow-y-scroll"
          @mouseenter="hovering = true"
          @mouseleave="hovering = false">
          <p class="text-center text-white">
            Already have an account?
            <a
              href="#"
              v-on:click="$router.replace({ path: $route.path, query: { tab: 'login' } })"
              :class="{
                '!text-white rounded-t-lg border-b-2 border-[#ff3860]':
                  activeTab === 'login',
              }"
              class="text-accent">
              Login
            </a>
          </p>
          <label
            for="username"
            class="block mt-4 mb-2 text-sm font-medium text-text-dim-color"
            >Username</label
          >
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 14C6.23858 14 4 16.2386 4 19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19C20 16.2386 17.7614 14 15 14H9Z"
                  fill="white" />
                <path
                  d="M12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2Z"
                  fill="white" />
              </svg>
            </div>
            <input
              v-model="form.username"
              required
              type="text"
              id="username"
              class="input-with-icon focus:ring-accent"
              placeholder="Your username here" />
          </div>
          <p v-if="usernameError" class="ml-0.5 mt-0.5 text-sm text-red-500 opacity-80">
            {{ usernameError }}
          </p>

          <label
            for="firstname"
            class="block mt-4 mb-2 text-sm font-medium text-text-dim-color"
            >First Name</label
          >
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 14C6.23858 14 4 16.2386 4 19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19C20 16.2386 17.7614 14 15 14H9Z"
                  fill="white" />
                <path
                  d="M12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2Z"
                  fill="white" />
              </svg>
            </div>
            <input
              v-model="form.firstName"
              required
              type="text"
              id="firstName"
              class="input-with-icon focus:ring-accent"
              placeholder="Your first name here" />
          </div>
          <p v-if="firstNameError" class="ml-0.5 mt-0.5 text-sm text-red-500 opacity-80">
            {{ firstNameError }}
          </p>

          <label
            for="lastName"
            class="block mt-4 mb-2 text-sm font-medium text-text-dim-color"
            >Last Name</label
          >
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 14C6.23858 14 4 16.2386 4 19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19C20 16.2386 17.7614 14 15 14H9Z"
                  fill="white" />
                <path
                  d="M12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2Z"
                  fill="white" />
              </svg>
            </div>
            <input
              v-model="form.lastName"
              required
              type="text"
              id="lastName"
              class="input-with-icon focus:ring-accent"
              placeholder="Your last name here" />
          </div>
          <p v-if="lastNameError" class="ml-0.5 mt-0.5 text-sm text-red-500 opacity-80">
            {{ lastNameError }}
          </p>

          <label
            for="email-address"
            class="block mt-4 mb-2 text-sm font-medium text-text-dim-color"
            >Email</label
          >
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803ZM7.56569 8.17538C7.11026 7.86296 6.4878 7.97889 6.17538 8.43431C5.86296 8.88974 5.97889 9.5122 6.43431 9.82462L10.3029 12.4785C11.3256 13.18 12.6744 13.18 13.6971 12.4785L17.5657 9.82462C18.0211 9.5122 18.137 8.88974 17.8246 8.43431C17.5122 7.97889 16.8897 7.86296 16.4343 8.17538L12.5657 10.8293C12.2248 11.0631 11.7752 11.0631 11.4343 10.8293L7.56569 8.17538Z"
                  fill="white" />
              </svg>
            </div>
            <input
              v-model="form.email"
              required
              type="email"
              id="email-address"
              class="input-with-icon focus:ring-accent"
              placeholder="Your email here" />
          </div>
          <div class="country-out">
            <label
              for="country"
              class="block mt-4 mb-2 text-sm font-medium text-text-dim-color">
              Country
            </label>
            <select
              v-model="form.country"
              @change="getStateByCountry"
              required
              disabled
              class="input-bet focus:ring-accent">
              <option value="us">US</option>
            </select>
          </div>
          <div class="state-out" v-if="form.country != ''">
            <label
              for="state"
              class="block mt-4 mb-2 text-sm font-medium text-text-dim-color">
              State/Province
            </label>
            <select v-model="form.state" required class="input-bet focus:ring-accent">
              <option value="">Select</option>
              <option v-for="(item, i) in statelist" v-bind:key="i" v-bind:value="item">
                {{ item }}
              </option>
            </select>
          </div>

          <label
            for="email-address"
            class="block mt-4 mb-2 text-sm font-medium text-text-dim-color">
            Date of Birth
            <span class="text-xs opacity-80">(ex. 09-09-1990)</span>
          </label>

          <div class="flex justify-between gap-2">
            <input
              v-model="form.day"
              required
              type="number"
              id="dob-day"
              maxlength="2"
              size="2"
              min="1"
              max="31"
              class="input-bet focus:ring-accent"
              placeholder="Day" />
            <input
              v-model="form.month"
              required
              type="number"
              id="dob-month"
              maxlength="2"
              size="2"
              min="1"
              max="12"
              class="input-bet focus:ring-accent"
              placeholder="Month" />
            <input
              v-model="form.year"
              required
              type="number"
              id="dob-year"
              maxlength="4"
              size="4"
              min="1900"
              max="2050"
              class="input-bet focus:ring-accent"
              placeholder="Year" />
          </div>
          <p v-if="dobError" class="ml-0.5 mt-0.5 text-sm text-red-500 opacity-80">
            {{ dobError }}
          </p>

          <label
            for="password"
            class="block mt-4 mb-2 text-sm font-medium text-text-dim-color"
            >Password</label
          >
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.16146 9H15.8385C16.3657 8.99998 16.8205 8.99997 17.195 9.03057C17.5904 9.06287 17.9836 9.13419 18.362 9.32698C18.9265 9.6146 19.3854 10.0735 19.673 10.638C19.8658 11.0164 19.9371 11.4096 19.9694 11.805C20 12.1795 20 12.6344 20 13.1615V17.8386C20 18.3657 20 18.8205 19.9694 19.195C19.9371 19.5904 19.8658 19.9836 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.9836 21.8658 17.5904 21.9371 17.195 21.9694C16.8205 22 16.3657 22 15.8385 22H8.16145C7.63431 22 7.17954 22 6.80498 21.9694C6.40963 21.9371 6.01641 21.8658 5.63803 21.673C5.07355 21.3854 4.6146 20.9265 4.32698 20.362C4.13419 19.9836 4.06287 19.5904 4.03057 19.195C3.99997 18.8205 3.99998 18.3657 4 17.8386V13.1614C3.99998 12.6343 3.99997 12.1795 4.03057 11.805C4.06287 11.4096 4.13419 11.0164 4.32698 10.638C4.6146 10.0735 5.07355 9.6146 5.63803 9.32698C6.01641 9.13419 6.40963 9.06287 6.80498 9.03057C7.17953 8.99997 7.63434 8.99998 8.16146 9ZM12 15C11.4477 15 11 15.4477 11 16V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V16C13 15.4477 12.5523 15 12 15Z"
                  fill="white" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V9H14V6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6V9H8V6Z"
                  fill="white" />
              </svg>
            </div>
            <input
              v-model="form.password"
              v-on:focus="startPvalidation"
              v-on:blur="stopPvalidation"
              v-on:keyup="validatepassword"
              required
              type="password"
              id="password"
              class="input-with-icon focus:ring-accent"
              placeholder="Your password here" />
          </div>
          <ul
            class="password-validatelist mt-2 text-[#e80000] font-small"
            v-if="this.passwordisvalidating">
            <li
              class="mb-1"
              :class="{ 'text-success-green': this.passwordValidation.case }">
              Includes lower and uppercase
            </li>
            <li
              class="mb-1"
              :class="{ 'text-success-green': this.passwordValidation.number }">
              At least 1 number
            </li>
            <li
              class="mb-1"
              :class="{ 'text-success-green': this.passwordValidation.length }">
              Minimum 8 characters long
            </li>
          </ul>
          <label
            for="password-confirmation"
            class="block mt-4 mb-2 text-sm font-medium text-text-dim-color"
            >Confirm Password</label
          >
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.16146 9H15.8385C16.3657 8.99998 16.8205 8.99997 17.195 9.03057C17.5904 9.06287 17.9836 9.13419 18.362 9.32698C18.9265 9.6146 19.3854 10.0735 19.673 10.638C19.8658 11.0164 19.9371 11.4096 19.9694 11.805C20 12.1795 20 12.6344 20 13.1615V17.8386C20 18.3657 20 18.8205 19.9694 19.195C19.9371 19.5904 19.8658 19.9836 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.9836 21.8658 17.5904 21.9371 17.195 21.9694C16.8205 22 16.3657 22 15.8385 22H8.16145C7.63431 22 7.17954 22 6.80498 21.9694C6.40963 21.9371 6.01641 21.8658 5.63803 21.673C5.07355 21.3854 4.6146 20.9265 4.32698 20.362C4.13419 19.9836 4.06287 19.5904 4.03057 19.195C3.99997 18.8205 3.99998 18.3657 4 17.8386V13.1614C3.99998 12.6343 3.99997 12.1795 4.03057 11.805C4.06287 11.4096 4.13419 11.0164 4.32698 10.638C4.6146 10.0735 5.07355 9.6146 5.63803 9.32698C6.01641 9.13419 6.40963 9.06287 6.80498 9.03057C7.17953 8.99997 7.63434 8.99998 8.16146 9ZM12 15C11.4477 15 11 15.4477 11 16V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V16C13 15.4477 12.5523 15 12 15Z"
                  fill="white" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V9H14V6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6V9H8V6Z"
                  fill="white" />
              </svg>
            </div>
            <input
              v-model="form.password_confirmation"
              required
              type="password"
              id="password-confirmation"
              class="input-with-icon focus:ring-accent"
              placeholder="Your password here" />
          </div>

          <div class="relative flex">
            <label
              for="referral code"
              class="block mt-4 mb-2 text-sm font-medium text-text-dim-color"
              >Referral Code (Optional)</label
            >
            <div class="flex items-center pt-2 ml-3">
              <input
                @click="form.isEnterCode = !form.isEnterCode"
                id="code"
                name="codecheckbox"
                type="checkbox"
                :checked="form.isEnterCode === true"
                class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-accent focus:ring-2 focus:ring-green-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600" />
            </div>
          </div>

          <div class="relative">
            <input
              v-if="form.isEnterCode"
              v-model="form.referralCode"
              required
              type="text"
              class="bg-secondary placeholder:text-text-dim-color border border-light-border text-white text-sm rounded-md font-semibold focus:border-0 block w-full px-2.5 py-3 focus:ring-accent" />
          </div>

          <div
            class="items-center block mt-4 mb-2 text-sm font-medium text-text-dim-color">
            <input
              v-model="form.accept_tos"
              id="tos"
              aria-describedby="checkbox-2"
              type="checkbox"
              class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-accent focus:ring-2 focus:ring-green-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
              required />
            <label for="tos" class="ml-1 text-sm font-medium text-slate-300">
              I am at least 18 years of age and have read and accept the
              <a
                class="underline"
                href="https://punt.com/docs/Terms-And-Conditions.pdf"
                target="_blank"
                >Terms of Use</a
              >
            </label>
          </div>

          <!--<label
        for="code"
        class="block mt-4 mb-2 text-sm font-medium text-text-dim-color"
        >Bonus Code (Optional)</label
      >
      <div class="relative">
        <div
          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11 15.5C11 15.2239 10.7761 15 10.5 15H5C4.44772 15 4 15.4477 4 16V18C4 19.6569 5.34315 21 7 21H10.5C10.7761 21 11 20.7761 11 20.5V15.5ZM13 20.5C13 20.7761 13.2239 21 13.5 21H17C18.6569 21 20 19.6569 20 18V16C20 15.4477 19.5523 15 19 15H13.5C13.2239 15 13 15.2239 13 15.5V20.5Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5 9C3.89543 9 3 9.89543 3 11C3 12.1046 3.89543 13 5 13H10.5C10.7761 13 11 12.7761 11 12.5V9.5C11 9.22386 10.7761 9 10.5 9H5ZM13.5 9C13.2239 9 13 9.22386 13 9.5V12.5C13 12.7761 13.2239 13 13.5 13H19C20.1046 13 21 12.1046 21 11C21 9.89543 20.1046 9 19 9H13.5Z"
              fill="white"
            />
            <path
              d="M9 3C7.89543 3 7 3.89543 7 5C7 6.10457 7.89543 7 9 7H10.5C10.7761 7 11 6.77614 11 6.5V5C11 3.89543 10.1046 3 9 3Z"
              fill="white"
            />
            <path
              d="M15 3C16.1046 3 17 3.89543 17 5C17 6.10457 16.1046 7 15 7H13.5C13.2239 7 13 6.77614 13 6.5V5C13 3.89543 13.8954 3 15 3Z"
              fill="white"
            />
          </svg>
        </div>
        <input
          v-model="form.code"
          type="text"
          id="code"
          class="input-with-icon focus:ring-accent"
          placeholder="Enter your code"
        />
      </div> -->
          <button :disabled="loading" class="btn-bet">
            {{ loading ? 'Please wait...' : 'Play Now' }}
          </button>
        </div>
      </form>
    </div>

    <!-- Conversion Pixel for [registration]- DO NOT MODIFY -->
    <img
      v-if="registerSuccess && newUserId"
      :src="
        'https://arttrk.com/pixel/?ad_log=referer&action=registration&order_id=' +
        newUserId +
        '&pixid=acb35c35-c9cb-4751-ab1b-358989a1a203'
      "
      width="1"
      height="1"
      border="0" />
    <!-- End of Conversion Pixel -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { doRecaptcha } from '@/lib/recaptcha';
import dateFormat from 'dateformat';
import { delay, fraudAndLocationPassed, proxyLocation } from '@/lib/utils';
import { getState } from '@/lib/state';
import Radar from 'radar-sdk-js';
import Loader from '@/components/Shared/Loader.vue';
import { setCookie, getCookie } from '@/lib/utils';
export default {
  name: 'RegisterForm',
  components: { Loader },
  data() {
    return {
      form: {
        accept_tos: false,
        game_mode: '',
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        country: 'us',
        state: '',
        password_confirmation: '',
        email: '',
        code: '',
        date: null,
        day: null,
        month: null,
        year: null,
        referralCode: null,
        isEnterCode: false,
        bannerTag: null,
      },
      widget: false,
      dobError: null,
      usernameError: null,
      firstNameError: null,
      lastNameError: null,
      loading: false,
      statelist: [],
      passwordisvalidating: false,
      passwordValidation: {
        length: false,
        case: false,
        number: false,
      },
      registerSuccess: false,
      newUserId: null,
      timeoutId: null,
      registerInProgress: false
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  created() {
    this.form.isEnterCode = false;
    this.getbannerTag();
    this.getReferralCodeValue();
    this.hadEnterCode();
  },
  methods: {
    getbannerTag() {
      switch (true) {
        case this.$route.params.btag != null && this.$route.params.btag !== '':
          this.form.bannerTag = this.$route.params.btag;
          break;
        case this.$route.query.btag != null && this.$route.query.btag !== '':
          this.form.bannerTag = this.$route.query.btag;
          break;
        case getCookie('btag') != null && getCookie('btag') !== '':
          this.form.bannerTag = getCookie('btag');
          break;
        default:
          this.form.bannerTag = null;
          break;
      }
    },
    getReferralCodeValue() {
      switch (true) {
        case this.$route.query.code != null && this.$route.query.code !== '':
          this.form.referralCode = this.$route.query.code;
          break;
        case this.$route.params.code != null && this.$route.params.code !== '':
          this.form.referralCode = this.$route.params.code;
          break;
        case this.form.bannerTag != null && this.form.bannerTag !== '':
          this.form.referralCode = this.form.bannerTag;
          break;
        default:
          this.form.referralCode = null;
          break;
      }
    },
    hadEnterCode() {
      if (this.form.referralCode !== null && this.form.referralCode !== '') {
        this.form.isEnterCode = true;
      }
    },
    getStateByCountry() {
      this.statelist = getState(this.form.country);
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleString(undefined, options);
    },
    onlyNumAndLetters() {
      const username = this.form.username;
      if (!username) return 'This field Required.';
      let res = /^[A-Za-z0-9]*$/.test(username);
      if (!res) {
        return 'Username should only have Letters and Numbers!';
      }
      return null;
    },
    validateDoB() {
      const date = new Date(this.form.year, this.form.month, this.form.day);
      if (!date) return 'This field Required.';
      const age = this.calculateAge(date);
      if (age < 18) {
        return 'You must be at-least 18 years old to proceed';
      }
      return null;
    },
    calculateAge(birthday) {
      // birthday is a date
      let ageDifMs = Date.now() - birthday;
      let ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },

    validateRegisterForm() {
      this.dobError = this.validateDoB();
      if (this.dobError) {
        return false;
      }
      this.validpassword = this.validatepassword();
      if (!this.validpassword) {
        this.$swal(
          'Uh-oh',
          'Password must should be  <br> Includes lower and uppercase<br> At least 1 number<br> Minimum 8 characters long<br> ',
          'error',
        );
        return false;
      }
      this.usernameError = this.onlyNumAndLetters(this.form.username);
      if (this.usernameError) {
        return false;
      }
      return true;
    },
    validatepassword() {
      let value = this.form.password;

      const containsUppercase = /[A-Z]/.test(value);
      const containsLowercase = /[a-z]/.test(value);

      if (containsUppercase && containsLowercase) this.passwordValidation.case = true;
      else this.passwordValidation.case = false;

      const containsNumber = /[0-9]/.test(value);
      if (containsNumber) this.passwordValidation.number = true;
      else this.passwordValidation.number = false;

      let plength = false;
      if (value.length > 8) plength = true;

      if (plength) this.passwordValidation.length = true;
      else this.passwordValidation.length = false;
      /*const containsSpecial = /[#?!@$%^&*-]/.test(value);

      if(containsSpecial)
        this.passwordValidation.number = true;
      else
        this.passwordValidation.number = false;*/

      return containsUppercase && containsLowercase && containsNumber && plength;
    },
    startPvalidation() {
      this.passwordisvalidating = true;
    },
    stopPvalidation() {
      if (this.validatepassword()) this.passwordisvalidating = false;
    },
    async register() {
      if (!this.validateRegisterForm()) return;
      try {
        // Clear the timeout if it exists
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
          this.timeoutId = null;
        }

        if(this.registerInProgress) return;

        this.loading = true;
        const form = { ...this.form };
        form.recaptcha = await doRecaptcha('signup');
        form.fp = window.fp;
        form.dob = dateFormat(
          new Date(this.form.year, this.form.month, this.form.day),
          'yyyy-mm-dd',
        );
        this.registerInProgress = true;
        let registerData = await this.performRegistration(form);

        // tracking signup event on GTM
        if (import.meta.env.VITE_NODE_ENV == 'production') {
          this.$gtm.trackEvent({
            event: 'signup',
            email: form.email,
          });
        }

        if (registerData.data !== undefined) {
          this.newUserId = registerData.data.user_id;
          this.registerSuccess = true;
          await this.setLoginToken(registerData.data);
          if (registerData.data?.redirect === 'pragmatic') {
            window.location.href = '/?first_time_login=pragmatic';
          } else if (registerData.data?.redirect === 'relax') {
            window.location.href = '/?first_time_login=relax';
          } else {
            window.location.href = '/';
          }
        }

        /*fbq('track', 'CompleteRegistration', {
          content_name: 'registrition_done',
          value: form.email,
        });*/
      } catch (e) {
        const { data, status } = e;
        if (typeof e.data.error !== 'undefined' && data && status && data.error) {
          this.$swal('Uh-oh', data.error, 'error');
        } else {
        }
      } finally {
        this.loading = false;
        this.registerInProgress = false;
      }
    },
    async login() {
      try {
        const recaptcha = await doRecaptcha('login');
        await this.performLogin({
          username: this.form.username,
          password: this.form.password,
          recaptcha,
        });
        await delay(2000);
        window.location.href = '/?first_time_login=1';
      } catch (e) {
        this.$toast.success('Account created. Please login!');
        await this.$router.replace({
          path: this.$route.path,
          query: { tab: 'login' },
        });
      } finally {
        this.loading = false;
      }
    },
    radarTrackAndRegister() {
      if (!this.validateRegisterForm()) return;

      this.loading = true;

      const GEO_TIMEOUT = 8000;
      this.timeoutId = setTimeout(() => {
        //this.$root.geoLocationPermissionEvent('Please turn on your location permission/service. Email us if you are still having issues at: help@punt.com', false); // Todo: API feature implementation
        this.register();
      }, GEO_TIMEOUT);

      Radar.trackOnce()
        .then((result) => {
          if(this.registerInProgress) return;

          this.register();

          // Todo: API feature implementation
          // const {user} = result;
          //
          // if (proxyLocation(user)) {
          //   let message = 'Access to our platform is not available for VPN connections. Please disable your VPN and try again. If you believe you\'re receiving this message in error, or if you need assistance, please contact our support team at: help@punt.com';
          //   this.$root.geoLocationPermissionEvent(message, this.isLoggedIn, false, true);
          // } else if (!fraudAndLocationPassed(user)) {
          //   let message = 'Your location is restricted from accessing the website. If you believe this is a mistake, please email: help@punt.com';
          //   this.$root.geoLocationPermissionEvent(message, this.isLoggedIn);
          // } else {
          //   this.register()
          // }
        })
        .catch((err) => {
          if(this.registerInProgress) return;

          this.register();
          // let error = err.toString();
          //
          // if (error.includes('RadarLocationPermissionsError')) {
          //   this.$root.geoLocationPermissionEvent('Please allow location permission/service to continue. If you need additional help, please email: help@punt.com', false, true);
          // }
          // else if (error.includes('RadarLocationError')) {
          //   this.$root.geoLocationPermissionEvent('Unable to determine your location, please try again. If you need additional help, please email: help@punt.com', false, true);
          // }
          // else if (error.includes('RadarRateLimitError')) {
          //   window.swal('Failed to register', 'Please try again.', 'error')
          // } else {
          //   this.register();
          // }
        });
    },
    ...mapActions(['performRegistration', 'performLogin', 'setLoginToken']),
  },
  mounted() {
    this.getStateByCountry();
  },
};
</script>

<style scoped>
.register-button {
  color: #013e01 !important;
  font-size: 1.3em;
  background-color: #1fff20;
  margin-top: 1em;
  font-weight: 600;
}

.register-button:hover,
.register-button:focus,
.register-button:active {
  background-color: #00b100 !important;
  color: #013e01 !important;
}

.my-control {
  margin-top: 10px;
}

.dp__input {
  border-radius: 2.75rem !important;
}

.dp__input_icon {
  left: 8px !important;
}

.dp__theme_dark,
.dp__theme_light {
  --dp-background-color: #0d1f28;
  --dp-text-color: #ffffff;
  --dp-hover-color: #484848;
  --dp-hover-text-color: rgb(203 213 225);
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #005cb2;
  --dp-primary-text-color: #ffffff;
  --dp-secondary-color: #a9a9a9;
  --dp-border-color: #2d2d2d;
  --dp-menu-border-color: rgb(13 31 40);
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #737373;
  --dp-scroll-bar-background: #212121;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #00701a;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #fff;
  --dp-danger-color: #e53935;
}
.scrollbar-style {
  scrollbar-width: thin;
  scrollbar-color:
    /*#132023*/ #64696a transparent;
}

/* Works on Chrome, Edge, and Safari */
.scrollbar-style::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-style::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #64696a;
  border-radius: 10px;
  border: 1px solid transparent;
}
</style>
