<template>
  <div class="flex flex-col flex-grow justify-between">
  <technical-warning
    :wtitle="me.freeze_reason"
    v-if="
      !me.can_purchase /* me.username!=null && me.username!='stagetester' &&me.username != 'zangtop1' && me.username != 'Smite' && me.username != 'vishal123' */
    " />
    
  <div v-else class="w-full flex flex-col text-slate-300">
    <div v-if="!showPayments" class="">

      <div  class="special-offer py-10  mb-5" v-if="this.activeSpecialDiscount.length > 0" >


           <div v-for="(discount, i) in this.activeSpecialDiscount" class=" mb-5">
              <p class="text-center relative  mb-5 font-bold text-2xl text-center sm:text-3xl">
             
                <span class="relative z-10 bg-primary px-1 "   >{{discount.title}}</span>
              
                <hr class="border-b-2   z-0 border-slate opacity-20 absolute top-[50%] w-[100%] left-0" />

              </p>
               <p class="text-center"
        >Claim Your One-Time Offer Today</p >  
                <div class="grid min-[350px]:grid-cols-2 md:grid-cols-3 gap-4 grid-cols-1 mt-5">
                   <div
              
                      v-for="(bundle, k) in discount.bundles"
                      class="flex flex-col rounded-xl bg-secondary hover:opacity-75 cursor-pointer "
                      :key="k"
                      :class="[
                        isDisabled(bundle.price) ? 'opacity-50' : '',
                        'border-4 border-yellow/80'  
                      ]"
                      v-on:click="onBundleClicked(bundle)"
                      :disabled="isOnBreak || isDisabled(bundle.price)"
                    >
                        <div
                          class="flex flex-row bundle-card relative my-2 ml-2 px-1.5 py-[3px] rounded-full items-center text-sm w-fit max-[480px]:ml-4 max-[480px]:text-[12px]">
                          <img
                            alt="USD Coin"
                            class="w-[13.33px] h-[13.33px]"
                            src="../../assets/coins/cash.png" />
                          <span class="ml-1">{{ bundle.actual_price }} free SC</span>
                        </div>

                        <div class="flex flex-col items-center justify-center px-3 pb-2.5 relative">
                          <img class="h-20 max-[575px]:h-16 mb-2.5" :src="bundle.img" alt="coins" />
                          <img
                            alt="shadow Image"
                            class="absolute top-[-41px] w-full h-full -translate-x-2/4 left-2/4 pointer-events-none"
                            src="/images/bank/bundlecardshadow.png" />
                          <span class="w-full">{{ (bundle.actual_price * 10000).toLocaleString() }}</span>
                          <span class="text-xs w-full text-primary-text">Gold Coins</span>
                          <button
                          
                            class="btn-bet bg-onprimary hover:bg-onprimary/90 rounded-[10px] hover:opacity-75 px-4 py-2.5 mt-3 w-full max-[575px]:px-3 max-[575px]:mt-2 max-[575px]:text-sm max-[575px]:rounded-xl max-[350px]:w-1/2">
                            <div  >
                                <span class="line-through-large mr-2">${{ bundle.actual_price }}</span>
                                <span class="text-lg">${{ getBundlePrice(bundle) }}</span>
                                
                              </div>
                          </button>
                        </div>
                  </div>
                </div>
           </div> 

      </div>

      
      <div class="flex flex-col gap-2 pb-4 pt-0">
        <span class="font-bold text-2xl text-center sm:text-3xl">Bundles</span>
        <span class="text-center"
          >Please choose one of the following bundle options</span
        >
      </div>
      <div class="grid min-[350px]:grid-cols-2 md:grid-cols-3 gap-4 grid-cols-1">
        <div
          v-for="(bundle, i) in bundles"
          class="flex flex-col rounded-xl bg-[rgba(215,215,215,0.08)]"
          :key="i"
          :class="[
            isDisabled(bundle.price) ? 'opacity-50' : '',
            hasPromotion(bundle) || hasSpecialdiscount(bundle) ? 'border-4 border-yellow/80' : '',
           
            
          ]"
        >
          <div
            class="flex flex-row bundle-card relative my-2 ml-2 px-1.5 py-[3px] rounded-full items-center text-sm w-fit max-[480px]:ml-4 max-[480px]:text-[12px]">
            <img
              alt="USD Coin"
              class="w-[13.33px] h-[13.33px]"
              src="../../assets/coins/cash.png" />
            <span class="ml-1">{{ getBundleValue(bundle) }} free SC</span>
          </div>
          <div class="flex flex-col items-center justify-center px-3 pb-2.5 relative">
            <img class="h-20 max-[575px]:h-16 mb-2.5" :src="bundle.img" alt="coins" />
            <img
              alt="shadow Image"
              class="absolute top-[-41px] w-full h-full -translate-x-2/4 left-2/4 pointer-events-none"
              src="/images/bank/bundlecardshadow.png" />
            <span class="w-full">{{ (getBundleValue(bundle) * 10000).toLocaleString() }}</span>
            <span class="text-xs w-full text-primary-text">Gold Coins</span>
            <button
              v-on:click="onBundleClicked(bundle)"
              :disabled="isOnBreak || isDisabled(bundle.price)"
              class="btn-bet bg-onprimary hover:bg-onprimary/90 rounded-[10px] hover:opacity-75 px-4 py-2.5 mt-3 w-full max-[575px]:px-3 max-[575px]:mt-2 max-[575px]:text-sm max-[575px]:rounded-xl max-[350px]:w-1/2">
              <div v-if="hasUpgradePromotion(bundle)">
                <span class="line-through-large mr-2">${{ getBundleValue(bundle) }}</span>
                <span class="text-lg">${{ bundle.price }}</span>
              </div>
              <div v-else-if=" hasSpecialdiscount(bundle)" >
                <span class="line-through-large mr-2">${{ bundle.price }}</span>
                <span class="text-lg">${{ getBundlePrice(bundle) }}</span>
              </div>
              <div v-else>
                ${{ getBundlePrice(bundle) }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex md:flex-row flex-col gap-x-6 coin_purchase justify-center mt-4 lg:mt-8"
      v-if="selectedBundle">
      <div
        class="mx-auto md:w-1/2 w-full pt-3 px-4 pb-10 coin_purchase_left_part max-w-sm rounded-xl">
        <div class="coin-img-wrapper">
          <img :src="selectedBundle.img" class="coinimage" />
        </div>
        <div
          class="mb-4 coin_purchase_left_content col-span-3 flex flex-row justify-between space-x-2">
          <h2 class="title">Gold Coins:</h2>
          <h3 class="text-x value font-bold text-white">
            {{ (getBundleValue(selectedBundle) * 10000).toLocaleString() }}
          </h3>
        </div>

        <div class="mb-4 col-span-3 flex flex-row justify-between space-x-2">
          <h2 class="title">Sweep Coin:</h2>
          <h3 class="text-x value font-bold text-white font-semibold">
            {{ getBundleValue(selectedBundle) }}
          </h3>
        </div>

        <div class="mb-4 col-span-3 flex flex-row justify-between space-x-2">
          <h2 class="title">Price:</h2>
          <h3 class="text-x value font-bold text-white font-semibold">
            ${{ getBundlePrice(selectedBundle).toFixed(2)   }}
          </h3>
        </div>
        <span
          class="mt-3 mb-2 border-b border-2 border-primary inline-block w-full"></span>
      </div>

      <div class="mx-auto md:w-1/2 w-full max-w-sm">
        <h2 class="mb-4 mr-4 md:mt-0 mt-5 text-x text-white font-semibold">
          Select Payment Method:
        </h2>

        <button
          class="mx-auto w-full max-w-sm rounded-xl pay_method_btn"
          v-on:click="paySafeDeposit"
          v-if="!me.is_frozen"
          v-bind:style="{ marginBottom: '16px', padding: '10px' }">
          <div>
            <div class="row flex mb-4 justify-between pay_method">
              <h2 class="font-bold font-semibold text-left text-white">
                Credit/Debit Card
              </h2>
              <span class="pay_method-span"><span></span></span>
            </div>
            <div class="row gap-[6px] pay_method_types grid grid-cols-3">
              <div class="img-wrapper">
                <img src="../../assets/paymentmethods/Visa.svg" />
              </div>
              <div class="img-wrapper">
                <img src="../../assets/paymentmethods/Mastercard.svg" />
              </div>
              <div class="img-wrapper">
                <img src="../../assets/paymentmethods/Amex.svg" />
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div v-if="selectedBundle">
    <button
        class="text-white bg-secondary px-4 py-3 flex gap-2 ml-auto rounded-[10px] items-center justify-center hover:opacity-75 coin_purchase_backbtn"
        v-on:click="backToItems">
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 1L1 6.99998L7 13M2 6.99998H17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        <span>Back to items</span>
      </button>
  </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import Arrow from '../../assets/svg/rightarrow.svg';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';
import TechnicalWarning from '@/components/TechnicalWarning.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Bundles',
  components: {
    TechnicalWarning,
    Arrow,
  },
  props: {
    showBuy: {
      default: true,
    },
    isOnBreak: {
      default: true,
    },
  },
  setup() {
    const balS = balanceStore();
    balS.init();
    balS.fetchActiveSpecialDiscount();
    const bundles = computed(() => balS.getAvailableBundle);
    const activePromotions = computed(() => balS.getActivePromotions);
    const activeSpecialDiscount = computed(() => balS.getActiveSpecialDiscount);
    return { bundles, balS, activePromotions,activeSpecialDiscount };
  },
  data() {
    return { showPayments: false, selectedBundle: null };
  },
  async created() {
    if (this.me && !this.me.username) {
      await this.fetchUser();
    }
  },
  async mounted() {
    if(import.meta.env.VITE_NODE_ENV == 'production') {
      this.$gtm.trackEvent({
        event: 'open_wallet',
        email: this.me.email,
      });
    }
  },
  methods: {
    ...mapActions(['fetchUser']),
    onBundleClicked(bundle) {
      if (this.isOnBreak) {
        this.$toast.error('Your "Break-In-Play" is active till: ' + this.me.break_till_date);
        return;
      }
      this.selectedBundle = bundle;
      this.showPayments = true;
      this.balS.bundleSelected = Math.round(bundle.price * 100) / 100;
    },
    backToItems() {
      this.selectedBundle = null;
      this.showPayments = false;
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    paySafeDeposit() {
      const page = 'buy';
      const promotionId = this.getPromotionId(this.selectedBundle);
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          ...{ action: 'paysafe', bundle: this.getBundlePrice(this.selectedBundle), page, promotionId },
        },
      });
      
    },
    getPromotionId(bundle) {
      return this.activePromotions[bundle.price]?.promotion_id || null;
    },
    isDisabled(amount)
      {
         
        return this.me.purchase_limit_bundles.includes(amount);
      },
    hasPromotion(bundle) {
      return !!this.activePromotions[bundle.price];
    },
    hasBlackFridaySale(bundle) {
     
      return   this.me.is_black_friday_sale_available && !!this.blackFridayBundles[bundle.price];
    },
    hasFTDSale(bundle) {
     
      return   this.me.ftp && !!this.FtpDiscountedBundles[bundle.price];
    },
    hasSpecialdiscount(bundle)
    {
        return  (this.getdiscountedbundlebyactualprice(bundle.price)!==false)?true:false;
    },
    getBundleValue(bundle) {
     
      if(this.getdiscountedbundlebyprice(bundle.price))
      {
        return (this.getdiscountedbundlebyprice(bundle.price)).actual_price;
      }
      const promotion = this.activePromotions[bundle.price];
      if (promotion) {
        const upgradeReward = promotion.rewards.find(r => r.reward_type === 'Bundle Upgrade');
        if (upgradeReward) {
          return Math.floor(upgradeReward.reward_amount);
        }
      }

      
      return bundle.price;
    },
    hasUpgradePromotion(bundle) {
      const promotion = this.activePromotions[bundle.price];
      return promotion && promotion.rewards.some(r => r.reward_type === 'Bundle Upgrade');
    },
    getdiscountedbundlebyactualprice(price)
    { 
       var found_discounted =false;
       this.activeSpecialDiscount.forEach( (discount)=> {
          if(discount.bundles.find(r=>r.actual_price == price))
            found_discounted =  discount.bundles.find(r=>r.actual_price == price);
      });

      return (found_discounted);
    },
    getdiscountedbundlebyprice(price)
    { 
       var found_discounted =false;
       this.activeSpecialDiscount.forEach( (discount)=> {
          if(discount.bundles.find(r=>r.price == price))
            found_discounted =  discount.bundles.find(r=>r.price == price);
      });

      return (found_discounted);
    },
    getBundlePrice(bundle)
    {
      const found_discounted = this.getdiscountedbundlebyactualprice(bundle.price);
      if(found_discounted)
        return found_discounted.price;
      else
        return bundle.price;
    }
  },
  computed: {
    ...mapGetters(['me']),
  },
  watch: {
    '$route.query.bundle': {
      handler(newState) {

        const selectedDiscountBundle = this.getdiscountedbundlebyprice(newState);
        if(selectedDiscountBundle)
        {
            this.onBundleClicked(selectedDiscountBundle);
        }
        else 
        {
          const selectedBundle = this.bundles.find(item => item.price == newState);
     
          if (selectedBundle && this.selectedBundle !== selectedBundle) {
            this.onBundleClicked(selectedBundle);
          }
        }
        
      },
      immediate: true,
    },
  }
};
</script>

<style scoped>
.bundle-card {
  background-color:rgba(0, 255, 186, 0.2);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
}
.coin_purchase .coin-img-wrapper {
  text-align: center;
  position: relative;
}
.coin_purchase .coin-img-wrapper img {
  display: block;
  margin: auto;
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
}
.coin_purchase .coin_purchase_left_part {
  background-image: url('../../assets/coins/coin_bg.png');
  background-position: bottom left;
  background-size: 100%;
  background-repeat: no-repeat;
}
.coin_purchase .coin-img-wrapper::before {
  content: url('../../assets/coins/bg_shadow.png');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100px;
  z-index: 0;
}
.coin_purchase .coin_purchase_left_content {
  position: relative;
  z-index: 5;
}
.coin_purchase .pay_method_btn .pay_method-span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2a3a3a;
  border-radius: 50%;
  border: 1px solid #415858;
}
.coin_purchase .pay_method_btn:hover .pay_method-span {
  border: 1px solid #00ffba;
}
.coin_purchase .pay_method_btn .pay_method-span span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.coin_purchase .pay_method_btn {
  border: 1px solid transparent;
  background: #112021;
}
.coin_purchase .pay_method_btn:hover {
  background:
    linear-gradient(#112021, #112021) padding-box,
    linear-gradient(213deg, #00ffba 50%, #0cce42 90%, #112021 114%) border-box;
}
.coin_purchase .pay_method_btn:hover .pay_method-span span {
  @apply bg-onprimary;
}
.pay_method_btn .pay_method_types img {
  max-width: 100%;
  margin: 0;
}
.pay_method_btn .pay_method_types .img-wrapper {
  padding: 14px 9px;
  background: #212f2f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}
.pay_method_btn:hover .pay_method_types .img-wrapper {
  opacity: 1;
}
@media screen and (max-width:768px){
  .coin_purchase_backbtn{
    margin: 10px ;
    margin: auto
  }
}
@media screen and (max-width:500px){
  .coin_purchase_backbtn{
    margin: 20px auto
  }
}

.line-through-large {
  text-decoration: line-through;
  text-decoration-thickness: 3px;
}
</style>
