import { sampleSize } from 'lodash';
import { defaultConvertor } from './lib/rsgp';
import $store from '@/store';

const ALPHA_NUM = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function* clientSeedGenerator(alpha, length) {
  while (true) {
    yield sampleSize(alpha, length).join('');
  }
}

export function clientSeedGeneratorFactory(
  opts = {
    alpha: ALPHA_NUM,
    length: 12,
  },
) {
  const { length, alpha } = opts;

  return clientSeedGenerator(alpha, length);
}

export function formatRsgp(amount) {
  return defaultConvertor.format(amount);
}
export function formatBlockChainUrl(coin, address) {
  // if (typeof url !== 'undefined' || url===null) {
  //    return "https://blockchair.com/bitcoin/transaction/";
  // }
  // return "https://blockchair.com/bitcoin/transaction/"+url;

  let currency = coin.toUpperCase();
  switch (currency) {
    case 'DOGE':
      var path = 'https://blockchair.com/dogecoin/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'ETH':
      var path = 'https://blockchair.com/ethereum/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'BTC':
      var path = 'https://blockchair.com/bitcoin/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'XRP':
      var path = 'https://blockchair.com/ripple/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'TRX':
      var path = 'https://tronscan.org/#/blockchain/transactions/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'EOS':
      var path = 'https://bscscan.com/token/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'BCH':
      var path = 'https://blockchair.com/bitcoin-cash/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
  }
}

export default {
  clientSeedGeneratorFactory,
}

export function checkIsSet(variable) {
  if (typeof variable !== 'undefined' || variable === null) {
    return false;
  }
  return true;
}

export function loadFallbackImage(event) {
    if (event.target.src !== event.target.getAttribute('data-fallback'))
        event.target.src = event.target.getAttribute('data-fallback');
    else return false;
}

export function getDefaultCategoryIcon() {
    return `${import.meta.env.VITE_AWS_CF_ASSET_URL}/categories/default.webp`;
}

export function getDefaultTileImage(game = null, provider = null) {
    if (game?.software === 'softswiss') {
        let gameProvider = provider ?? game?.provider;
        const identifier = game.identifier.split(':')[1];

        return `https://cdn.softswiss.net/i/s4/${gameProvider}/${identifier}.png`;
    }

    return `${import.meta.env.VITE_AWS_CF_ASSET_URL}/images/default.webp`;
}

export function getCategoryIcon(category) {
    return `${import.meta.env.VITE_AWS_CF_ASSET_URL}/${category.icon}`;
}

export function getRaceImage(imageName){
  return `${import.meta.env.VITE_AWS_CF_ASSET_URL}/races/${imageName}`;
}

export function getTileImage(game, provider = null, thumbnail = false) {
    let gameProvider = provider ?? game?.provider;
    let identifier = game.identifier;
    if (game?.software === 'softswiss') {
        identifier = game.identifier.split(':')[1];
    }

    if (gameProvider === 'runebet') {
        identifier = game.identifier.replace('original-', '');
        identifier = identifier.replace(/-/g, '');
    }

    return thumbnail ? `${import.meta.env.VITE_AWS_CF_ASSET_URL}/images/${game.software}/100/${identifier}-100.webp` : `${import.meta.env.VITE_AWS_CF_ASSET_URL}/images/${game.software}/${identifier}.webp`;
}

export function getGameLink(game, provider = null) {
    if (!$store.getters.isLoggedIn) {
      return `/?tab=register`;
    }
    let gameProvider = provider ?? game?.provider;
    if (gameProvider === 'runebet') {
        let gameName = game.identifier.replace('original-', '');
        gameName = gameName.replace(/-/g, '');

        return `/casino/${gameName}`;
    }

    if (game.software == 'relax') {
        return `/casino/games/${gameProvider}-${game.identifier}`;
    }

    if (game.software == 'softswiss') {
        const gameName = game.identifier.split(':')[1];
        return `/softswiss/casino/games/${gameProvider}-${gameName}`;
    }

    if (game.software == 'pragmatic') {
        return `/pragmatic/casino/games/${game.identifier}`;
    } 
    
    if (game.software == '3oaks') {
    
      return `/3oaks/casino/games/${gameProvider}-${game.identifier}`;
 
    }

    if (game.software == 'evolution') {
        return `/evolution/casino/games/${game.identifier}`;
    }

   
}

export function getErrorCodeMessage(e, defaultMessage = 'Unable to process your request at this time') {
  if (e.response && e.response.data && (e.response.data.errors || e.response.data.error || e.response.data.message)) {
    return {
      code: e.response.data.code || e.response.data.status || 'UNKNOWN_ERROR',
      message: Array.isArray(e.response.data.errors) ? e.response.data.errors.join(', ') : e.response.data.error || e.response.data.errors || e.response.data.message,
    };
  }

  if (e.data && (e.data.errors || e.data.error || e.data.message)) {
    return {
      code: e.data.code || e.data.status || 'UNKNOWN_ERROR',
      message: Array.isArray(e.data.errors) ? e.data.errors.join(', ') : e.data.error || e.data.errors || e.data.message,
    };
  }

  return {
    code: 'UNKNOWN_ERROR',
    message: defaultMessage,
  };
}