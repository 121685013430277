<template>
  <div class="px-4">
    <div
      class="max-w-[450px] mx-auto md:p-6 p-3 w-full mt-[10vh] mb-[4vh] bg-[rgb(37_37_70_/_30%)] rounded-xl md:mt-[16vh] md:mb-[8vh]">
      <h1 class="text-xl font-bold text-white mb-2 max-[767px]:text-lg">
        Forgot password
      </h1>
      <div class="flex flex-col">
        <form @submit.stop.prevent="() => forgotPassword()">
          <label
            for="email-address"
            class="mt-4 max-[575px]:mt-2 block mb-2 text-sm font-medium text-text-dim-color"
            >Email Address</label
          >
          <div class="relative">
            <div
              class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803ZM7.56569 8.17538C7.11026 7.86296 6.4878 7.97889 6.17538 8.43431C5.86296 8.88974 5.97889 9.5122 6.43431 9.82462L10.3029 12.4785C11.3256 13.18 12.6744 13.18 13.6971 12.4785L17.5657 9.82462C18.0211 9.5122 18.137 8.88974 17.8246 8.43431C17.5122 7.97889 16.8897 7.86296 16.4343 8.17538L12.5657 10.8293C12.2248 11.0631 11.7752 11.0631 11.4343 10.8293L7.56569 8.17538Z"
                  fill="white" />
              </svg>
            </div>
            <input
              v-model="form.email"
              required
              type="email"
              id="email-address"
              class="input-bet pl-12 p-2.5"
              placeholder="Your email address here" />
          </div>

          <button
            class="bg-secondary-light disabled:opacity-80 disabled:cursor-not-allowed hover:opacity-70 flex flex-row items-center space-x-2 justify-center w-full mt-4 p-3 text-white font-semibold rounded-xl">
            {{ loading ? 'Please wait...' : 'Recover Password' }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { doRecaptcha } from '@/lib/recaptcha';
import { authStore } from '@/store/pinia/authStore';
import { getErrorCodeMessage } from '@/utils';

export default {
  name: 'ForgotPassword',
  setup() {
    return {
      authS: authStore(),
    };
  },
  data() {
    return {
      form: {
        email: '',
        token: '',
      },
      loading: false,
      widget: false,
    };
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.go(-1);
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    async forgotPassword() {
      try {
        this.loading = true;
        this.form.recaptcha = await doRecaptcha('forgot_password');
        const { data } = await this.authS.resetPassword(this.form);
        this.form = {
          email: '',
          recaptcha: '',
        };
        this.$toast.success(data.status);
      } catch (e) {
        const { message } = getErrorCodeMessage(e, 'Unable to reset password!');
        this.$toast.error(message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
