<template>
  <router-view></router-view>
  <div class="w-full flex flex-col items-center">
    <div
      :class="{ 'p-4 md:p-8 max-w-[1200px]': !generalS.isTheatreMode }"
      class="w-full flex flex-col items-center">
      <div class="flex flex-col w-full max-w-[calc(138vh)] 2xl:max-w-[calc(160vh)]">
        <div class="flex flex-col w-full h-full items-center">
          <div
            id="clovercross_game_wrapper"
            class="relative w-full child:rounded-t-lg h-0 pt-[56.25%] child:absolute child:top-0 child:left-0 child:w-full child:h-full">
            <iframe :key="iframeKey" :src="gameUrl" />
          </div>
          <game-actions
            :pre-extras="gameActions"
            :document-id="'clovercross_game_wrapper'"
            :game-data="{ name: 'Clover Cross', id: null }"
            class="bg-secondary-light rounded-b-lg" />
        </div>

        <game-details
          game="Clover Cross"
          edge="1"
          provider="Punt Originals"
          class="mt-6 md:mt-12" />
      </div>
    </div>
  </div>
  <game-history-table class="hidden md:flex" />

  <img class="h-0 w-0" src="../../assets/svg/mines/dmd.png" />
  <img class="h-0 w-0" src="../../assets/svg/mines/bomb-lg.png" />
</template>

<script>
import { defaultConvertor } from '../../lib/rsgp';
import { mapGetters } from 'vuex';
import GameHistoryTable from './../GameHistory/BetsHistory.vue';
import GameActions from '../Shared/GameActions.vue';
import GameDetails from '../Shared/GameDetails.vue';
import { generalStore } from '@/store/pinia/generalStore';
import { balanceStore } from '@/store/pinia/balanceStore';
import { isMobileOnly } from 'mobile-device-detect';

export default {
  components: {
    GameHistoryTable,
    GameActions,
    GameDetails,
  },
  props: {},
  created() {
    if (isMobileOnly) {
      window.location.href = this.gameUrl;
    }
  },
  setup() {
    const generalS = generalStore();
    const balS = balanceStore();
    return { generalS, balS };
  },
  data: () => ({
    currentState: 'notStarted',
    activeMode: 'Manual',
    game: null,
    gameActions: [['/casino/clovercross/rules', 'Rules']],
    iframeKey: Date.now()
  }),
  watch: {
    'balS.mainCurrency'() {
      this.iframeKey = Date.now();
    }
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    ...mapGetters('Sound', ['isSoundMuted']),
    isManualMode() {
      return this.activeMode === 'Manual';
    },
    gameUrl() {
      return (
        import.meta.env.VITE_FRONTEND_URL +
        'games/clovercross/index.html?pusher_key=' +
        import.meta.env.VITE_PUSHER_APP_KEY +
        '&domain=' +
        import.meta.env.VITE_BACKEND_URL
      );
    },
  },
  methods: {
    rsgp(x) {
      return defaultConvertor.format(x);
    },
  },
};
// test
</script>
