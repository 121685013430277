<template>
  <Loader v-if="isLoading" />
  <div v-else class="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full sm:px-6">
      <table class="table-auto md:table-fixed w-full">
        <thead class="">
          <tr>
            <th
              class="md:py-3 md:px-6 py-1 px-3 text-sm text-left text-gray-400 w-[100px]"
              scope="col">
              #
            </th>
            <th
              class="md:py-3 md:px-6 py-1 px-3 text-sm text-center text-gray-400"
              scope="col">
              Created
            </th>

            <th
              class="md:py-3 md:px-6 py-1 px-3 text-sm text-center text-gray-400 w-[140px]"
              scope="col">
              Amount
            </th>
            <th
              class="md:py-3 md:px-6 py-1 px-3 text-sm hidden md:table-cell text-left text-gray-400"
              scope="col">
              Tx Fee
            </th>
            <th
              class="md:py-3 md:px-6 py-1 px-3 hidden md:table-cell text-sm text-left text-gray-400"
              scope="col">
              Mining Fee
            </th>
            <th
              class="md:py-3 md:px-6 py-1 px-3 text-sm text-right text-gray-400 w-[120px]"
              scope="col">
              Status
            </th>
            <th
              class="hidden md:table-cell md:py-3 md:px-6 py-1 px-3 text-sm text-center text-gray-400"
              scope="col">
              Blockchain
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(transaction, i) in transactions.data"
            v-bind:key="i"
            class="odd:bg-secondary-light odd:opacity-90 rounded-lg"
            v-on:click="selectRow(transaction)">
            <td class="md:py-4 md:px-6 py-2 px-3 text-sm font-medium text-slate-300">
              <a
                :href="transaction.blockchain_link"
                class="text-blue-600 hidden visited:text-purple-600"
                target="_blank">
                {{ transaction.id.toLocaleString() }}
              </a>
              <label
                class="md:flex flex-row items-center justify-start text-start w-full">
                {{ transaction.id.toLocaleString() }}
              </label>
            </td>

            <td class="md:py-4 md:px-6 py-2 px-3 text-sm font-medium text-slate-300">
              <label class="flex flex-row items-center justify-center text-center w-full">
                {{ this.formattedTime(transaction.created_at) }}
              </label>
            </td>

            <td class="md:py-4 md:px-6 py-2 px-3 text-sm text-gray-400 text-left">
              <!--<label class="flex flex-row items-center justify-end text-center w-full"
              > {{ this.balS.exchangeAndFormatAmount(transaction.amount, transaction.currency) }}
                <img alt="icon" :src="getIcon(transaction.currency)" class="ml-2 h-4 w-4"/>

              </label>-->

              <label
                v-for="(log, i) in transaction.logs.slice().reverse()"
                v-bind:key="i"
                class="flex flex-row items-center justify-end text-center w-full">
                {{ parseFloat(log.amount).toFixed(2) }}

                <img
                  v-if="log.currency == 'SC'"
                  alt="icon"
                  src="../../assets/coins/cash.png"
                  class="ml-2 h-4 w-4" />
                <img
                  v-if="log.currency == 'GC'"
                  alt="icon"
                  src="../../assets/coins/gold.png"
                  class="ml-2 h-4 w-4" />
              </label>
            </td>
            <td
              class="hidden md:table-cell md:py-4 md:px-6 py-2 px-3 text-sm text-gray-400 text-left">
              {{ transaction.tx_fee }}
            </td>
            <td
              class="hidden md:table-cell md:py-4 md:px-6 py-2 px-3 text-sm text-gray-400 text-left">
              {{ transaction.mining_fee }}
            </td>

            <td class="md:py-4 md:px-6 py-2 px-3 text-sm text-gray-400 text-left">
              <div class="flex flex-row items-center justify-end text-right w-full">
                <span class="hidden md:block">{{
                  this.setCapital(transaction.status)
                }}</span>
                <span
                  class="ml-2"
                  v-if="this.setCapital(transaction.status) === 'Confirmed'">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 fill-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
                <span class="ml-2" v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 fill-yellow-500"
                    viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
              </div>
            </td>

            <td class="hidden md:table-cell md:py-4 md:px-6 py-2 px-3 text-sm text-left">
              <a
                v-if="transaction.gateway_type == 'crypto'"
                :href="transaction.blockchain_link"
                class="px-4 py-2 rounded-full bg-white text-slate-900 font-semibold hover:bg-slate-200"
                target="_blank">
                View
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="flex items-center justify-center tailwind-custom-pagination">
    <TailwindPagination
      :data="transactions"
      @pagination-change-page="getTransactions"
      :active-classes="activeCls" />
  </div>

  <div class="grow">
    <div v-if="isLoggedIn" class="flex items-center justify-center max-[575px]:gap-x-0 gap-x-4">
      <button
        class="flex-inline new-wallet-btn flex h-10 sm:h-12 mt-7 mb-5 flex-row items-center justify-center gap-2 rounded-lg sm:rounded-xl bg-onprimary font-extrabold text-black/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 px-2 sm:px-5 py-2 sm:py-3 text-sm sm:text-base w-full sm:w-auto"
        v-on:click="generateCSV">
        Download all Purchases
      </button>
    </div>
  </div>


</template>

<script>
import dateFormat from 'dateformat';

import axios from '../../lib/myAxios';
import { balanceStore } from '@/store/pinia/balanceStore';
import { ordersStore } from '@/store/pinia/ordersStore';
import { mapGetters } from 'vuex';
import { getBalanceIcon } from '@/lib/icons';
import Loader from '@/components/Shared/Loader.vue';

export default {
  name: 'Deposits',
  components: { Loader },
  setup() {
    const balS = balanceStore();
    const orderS = ordersStore();
    return { balS, ordersStore };
  },
  data: () => ({
    transactions: [],
    activeCls: ['active-page'],
    isOpen: false,
    isLoading: true,
  }),
  props: {},
  methods: {
    setCapital(str) {
      if (str === null || str === 'undefined') {
        return 'Pending ';
      }

      const titleCase = str
        .toLowerCase()
        .split(' ')
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');

      return titleCase;
    },
    getTransactions(page = 1) {
      this.isLoading = true;
      return axios
        .get(`transactions/deposit?page=${page}`)
        .then((res) => res.data)
        .then((item) => {
          this.transactions = item;
          this.isLoading = false;
        });
    },
    getAllTransactions() {
      this.isLoading = true;
      return axios.get('get-all-transaction-amounts/deposit')
        .then(response => {
          this.isLoading = false;
          return response.data;
        })
        .catch(error => {
          this.isLoading = false;
          console.error('Error fetching all transactions:', error);
          return [];
        });
    },
    generateCSV() {
      this.getAllTransactions().then(allTransactions => {
        const csvData = allTransactions.data.map((transaction, idx) => ({
          id: idx+1,
          created_at: this.formattedTime(transaction.created_at),
          amount: `${parseFloat(transaction.GC).toFixed(2)}\nFREE SC:${parseFloat(transaction.SC).toFixed(2)}`
        }));

        const headers = ['ID', 'Created At', 'Amount'];
        const rows = csvData.map(transaction => [
          transaction.id,
          transaction.created_at,
          transaction.amount
        ]);

        let csvContent = headers.join(',') + '\n';
        rows.forEach(row => {
          csvContent += row.map(cell => `"${String(cell).replace(/"/g, '""')}"`).join(',') + '\n';
        });

        const currentDate = dateFormat(new Date(), 'yyyymmdd');

        const firstTransactionId = allTransactions? allTransactions.username : 'unknown';

        const fileName = `${firstTransactionId}_purchases_${currentDate}.csv`;

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    formattedTime(timestamp) {
      return dateFormat(new Date(timestamp), 'yyyy:mm:dd');
    },
    formatBlockChainUrl(coin, address) {
      if (address === null) {
        return 'https://www.blockchain.com/en/search?search=';
      }
      let currency = coin.toUpperCase();
      switch (currency) {
        case 'DOGE':
          return 'https://blockchair.com/dogecoin/transaction/' + address;
          break;
        case 'ETH':
          return 'https://blockchair.com/ethereum/transaction/' + address;
          break;
        case 'BTC':
          return 'https://blockchair.com/bitcoin/transaction/' + address;
          break;
        case 'XRP':
          return 'https://blockchair.com/ripple/transaction/' + address;
          break;
        case 'TRX':
          return 'https://tronscan.org/#/blockchain/transactions/' + address;
          break;
        case 'EOS':
          return 'https://bscscan.com/token/' + address;
          break;
        case 'BCH':
          return 'https://blockchair.com/bitcoin-cash/transaction/' + address;
          break;
      }
    },
    selectRow(transaction) {
      this.$router.push('/transactions/deposits/details');
    },

    closeModal() {
      isOpen.value = false;
    },
    openModal() {
      this.isOpen.value = true;
    },
  },
  created() {
    try {
      this.getTransactions();
    } catch(error) {
      console.log('issue while fetching transactions');
    }
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn', 'userBalance']),
  },
  watch: {
    'orderS.updated': {
      handler: function (selected, old) {
        try {
          this.getTransactions();
        } catch(error) {
          console.log('issue while fetching transaction')
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
