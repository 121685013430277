<template>
  <punt-modal
    size="md:max-w-xl max-w-[92%] "
    v-if="showconfirmation == true"
    :onClose="closeModal">
    <template #body>
      <div
        class="modal payment-modal is-active flex min-h-[300px] flex-col items-center space-y-2 text-slate-300">
        <!--<img
                v-on:click="reload"
                class="blogo  max-h-8 hover:opacity-80"
                src="../assets/logo.png"
                />
                -->

        <div class="text-block">
          <h3 class="font-bold mb-3 text-3xl text-[#6aff14]">GOODS DELIVERED</h3>

          <p class="text-center mb-3">
            <img class="hs-24 w-24 m-auto" :src="bundle.img" alt="coins" />
          </p>

          <p class="mt-3 text-center">
            Congratulations on purchasing {{ orderdata.gc }} Gold Coins! You have received
            {{ orderdata.sc }} FREE Sweep Coins.
          </p>
          <p class="mt-3">By clicking "I CONFIRM", you agree to the following:</p>

          <div class="mt-3">
            <ol class="order-list">
              <li>You have Authorized the purchase;</li>
              <li>You have Received EXACTLY what you purchased;</li>
              <li>Your purchases were EXACTLY what was described and NOT defective;</li>
              <li>You understand and acknowledge that there are NO REFUNDS;</li>
              <li>
                You are legally bound to our Terms of Service; Payment FAQ, and to this
                Confirmation
              </li>
            </ol>
          </div>

          <p class="mt-5 mb-5">
            <button
              v-if="this.loading == false"
              @click="this.changestatus('1')"
              class="mr-8 text-primary inline-block gap-2 rounded-xl bg-[#6aff14] font-semibold text-black/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 disabled:opacity-5">
              Confirm
            </button>
            <button
              v-if="this.loading == false"
              @click="this.reconfirm = true"
              class="gap-2 inline-block rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 disabled:opacity-5">
              Deny
            </button>
          </p>
          <p class="text-red-400 text-xs">
            This confirmation is LEGALLY BINDING. Denying the delivery will result in your
            account being frozen, and a refund for this particular payment once processed
            by our Compliance Department
          </p>
        </div>
      </div>
    </template>
  </punt-modal>

  <punt-modal size="md:max-w-xl max-w-[92%] " v-if="reconfirm">
    <template #body>
      <div
        class="modal payment-modal is-active min-h-[300px] items-center space-y-2 text-slate-300">
        <p class="text-red-400 mb-5">
          WARNING: Are you sure you want to deny Delivery of this purchase? By denying
          delivery, your account will be frozen and you will not be able to play any
          games.
        </p>

        <button
          v-if="this.loading == false"
          @click="this.changestatus('2')"
          class="mr-8 text-primary inline-block gap-2 rounded-xl bg-[#6aff14] font-semibold text-black/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 disabled:opacity-5">
          YES
        </button>
        <button
          v-if="this.loading == false"
          @click="this.reconfirm = false"
          class="gap-2 inline-block rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 disabled:opacity-5">
          No, I have made a mistake.
        </button>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import Twenty from '../../assets/coins/20.png';
import Thirty from '../../assets/coins/30.png';
import Fifty from '../../assets/coins/50.png';
import Hundred from '../../assets/coins/100.png';
import TwoHundred from '../../assets/coins/200.png';
import ThreeHundred from '../../assets/coins/300.png';
import axios from '@/lib/myAxios';
import { balanceStore } from '@/store/pinia/balanceStore';

export default {
  name: 'PaymentConfirmModel',
  components: { PuntModal },
  async created() {
    await this.fetchIspendingConfirmation();
  },
  data() {
    return {
      showconfirmation: false,
      loading: false,
      orderdata: false,
      reconfirm: false,
      bundle: [],
    };
  },
  methods: {
    async fetchIspendingConfirmation() {
      try {
        const data = await axios.get('/pendingpaymentconfirmation');

        if (data.data && data.data.id > 0) {
          this.showconfirmation = true;
          this.orderdata = data.data;
          if (this.orderdata.usd_amount <= 5) this.bundle = { img: Twenty };
          else if (this.orderdata.usd_amount > 5 && this.orderdata.usd_amount <= 20)
            this.bundle = { img: Thirty };
          else if (this.orderdata.usd_amount > 20 && this.orderdata.usd_amount <= 50)
            this.bundle = { img: Fifty };
          else if (this.orderdata.usd_amount > 50 && this.orderdata.usd_amount <= 100)
            this.bundle = { img: Hundred };
          else if (this.orderdata.usd_amount > 100 && this.orderdata.usd_amount <= 200)
            this.bundle = { img: Hundred };
          else this.bundle = { img: TwoHundred };
        } else {
          this.showconfirmation = false;
        }
      } catch {
        console.log('not found pending ');
        this.showconfirmation = false;
      }
    },
    async changestatus($status) {
      this.loading = true;
      this.reconfirm = false;
      balanceStore().fetchActiveSpecialDiscount();
      try {
        const data = await axios.post('/confirmpayment', { status: $status });
      } catch (error) {
        console.log('checking confirmation');
      }
      this.loading = false;
      this.showconfirmation = false;
      this.fetchIspendingConfirmation();
    },
    closeModal() {
      return '';
    },
  },
  watch: {
    $route(to, from) {
      this.fetchIspendingConfirmation();
    },
  },
};
</script>
